
.msgBoxClass {
  background-color: #0c0e3f !important;
  border: 1px solid #0c0e3f !important;
  p,
  .el-message-box__title {
    color: #ffffff;
  }
  .el-icon-close:before {
    color: #ffffff;
  }
  .el-message-box__content {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .el-message-box__btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 80px;
      height: 36px;
    }
  }
  .el-message-box__btns button:nth-child(1) {
    border: 1px solid #262963 !important;
    border-color: #262963 !important;
    color: #d3d3d3;
    background-color: transparent !important;
  }
}
