::v-deep .el-cascader__tags .el-tag {
    background-color: rgb(12, 14, 63);
}

::v-deep .el-breadcrumb__item {
    padding: 20px 0 0 22px;
}

::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: rgb(255, 255, 255);
}

 ::v-deep .el-input__inner {
    background-color: rgb(12, 14, 63);
    color: #ffffff;
    border-color: rgb(12, 14, 63);
}

 ::v-deep .el-checkbox-button.is-disabled .el-checkbox-button__inner {
    background-color: rgb(12, 14, 63);
}

 ::v-deep .el-input.is-disabled .el-input__inner {
    background-color: rgb(12, 14, 63);
    border-color: rgb(12, 14, 63);
}

 ::v-deep .el-checkbox__inner {
    background-color: rgb(12, 14, 63);
    border-color: #262963;
}

 ::v-deep .el-cascader__search-input {
    background-color: rgb(12, 14, 63);
}

 ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #ababbb;
}

 ::v-deep div span {
    color: #ffffff;
}

.common-title {
    color: #ffffff;
}

 ::v-deep .el-form-item__content {
    margin-left: 0 !important;
}

 ::v-deep .el-form-item {
    margin-bottom: 0 !important;
}

 ::v-deep .el-radio-button__inner,
 ::v-deep .el-checkbox-button__inner {
    width: 82px !important;
    background-color: rgb(12, 14, 63);
    border: none !important;
    border-radius: 4px;
    color: #ababbb;
}

 ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner,
 ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: rgb(12, 14, 63);
    border: 1px solid #4b45ff !important;
    border-radius: 4px;
    box-shadow: none;
    transition: none;
    color: #ffffff;
}

 ::v-deep .el-checkbox {
    margin: 0 0 16px 11px;
    display: flex;
    align-items: center;
}

// 页面统一布局
.layout-common {
    background: #070932;
    height: auto;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
    padding: 30px 61px 40px 21px;
}

.delivery-common {
    text-align: center;
    line-height: 30px;
    border-radius: 4px;
    cursor: pointer;
}

.delivery-common-btn {
    border: 1px solid #4b45ff;
    color: #ffffff;
    margin-right: 20px;
}

.delivery-unchecked {
    background: #0c0e3f;
    color: #ababbb !important;
    border: none !important;
}

::v-deep .el-button--primary.is-plain {
    background: rgba(38, 41, 99, 1);
    border: 1px solid #262963;
}

.new-advertising {
    height: 100%;
    display: flex;
    flex-direction: column;
    .ad-title {
        font-weight: bold;
        font-size: 16px;
        margin: 18px 0 0 21px;
        color: #fff;
    }
    .delivery-settings-main {
        display: flex;
        flex-direction: column;
        .cost-delivery {
            border: 1px solid #4b45ff;
            border-radius: 4px;
            width: 280px;
            height: 60px;
            padding: 13px 0 0 10px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            .cost-delivery-tag {
                font-size: 12px;
                color: #ababbb;
            }
        }
        .delivery-speed {
            display: flex;
            flex-direction: column;
            .delivery-option {
                display: flex;
            }
        }
        .transformation-target {
            .liveroom-box {
                box-sizing: border-box;
                width: 212px;
                height: 60px;
                background: #0c0e3f;
                border: 1px solid #4b45ff;
                border-radius: 4px;
                padding: 13px 0 13px 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .live-icon {
                    font-size: 32px;
                    color: #4b45ff;
                    margin-right: 22px;
                }
                .liveroom-more {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        // 投放时间
        .launch-time-box {
            display: flex;
        }
        .fixed-period-table {
            border: 1px solid #262963;
            background-color: rgb(12, 14, 63);
            // width: fit-content;
            width: 100%;
            overflow: hidden;
            overflow-x: auto;
            color: #ffffff;
            .period-table {
                border-collapse: collapse;
                border-radius: 4px;
                tr,
                th,
                td {
                    border: 1px solid #262963;
                    text-align: center;
                    .btn-common {
                        position: relative;
                        left: 34%;
                    }
                }
                .period-table-header {
                    border: 1px solid #262963;
                     ::v-deep .el-button:hover {
                        border-color: none !important;
                        background-color: none !important;
                    }
                }
            }
            .choose-box {
                width: 70px;
                height: 70px;
            }
            .chosen_td {
                background-color: #4b45ff;
            }
            .disable-td {
                background-color: rgb(7, 9, 45);
            }
        }
    }
    .population-main {
        display: flex;
        .population-main-left {
            .population-main-region {
                .region-option {
                    display: flex;
                }
            }
            .population-main-age,
            .population-main-network {
                display: flex;
                flex-direction: column;
                .age-option,
                .network-option {
                    display: flex;
                    align-items: center;
                }
            }
            .population-main-interest {
                .interset-option {
                     ::v-deep .el-radio-button__inner {
                        width: 90px !important;
                    }
                    .interset-show {
                        width: 200px;
                        height: auto;
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
            .population-main-more {
                .more-option {
                    margin-top: 20px;
                    display: flex;
                }
            }
            .more-checkbox {
                margin-top: 10px;
                height: 255px;
                width: 373px;
                border-radius: 4px;
                background: #0c0e3f;
                box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.2);
                .more-checkbox-title {
                    padding: 11px 0 15px 11px;
                    font-weight: bold;
                    height: 18px;
                    color: #ababbb;
                    background: #191c51;
                    border-radius: 4px 4px 0 0;
                }
                .more-tag {
                    display: inline-block;
                    margin-left: 11px;
                    width: 30px;
                    height: 20px;
                    background: #191c51;
                    padding: 3px 5px 0 7px;
                    color: #ababbb !important;
                    border-radius: 2px;
                }
                .more-common {
                    color: #ababbb !important;
                }
            }
        }
        .population-main-right {
            position: relative;
            //left: 25%;
            width: 445px;
            height: 100%;
            background: #0c0e3f;
            border: 1px solid #0c0e3f;
            border-radius: 4px;
            padding: 20px 20px 25px 20px;
            color: #ababbb;
            .estimate-data {
                margin-top: 17px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .coverage-data,
                .advertisement-data {
                    width: 184px;
                    height: 95px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title-common {
                        color: #ffffff;
                        padding-left: 15px;
                        margin-bottom: 5px;
                    }
                    .coverage-data-num {
                        padding-left: 15px;
                        color: #ffffff;
                        font-size: 30px;
                        font-weight: bold;
                    }
                }
                .advertisement-data {
                    background: url(../../../../assets/image/student/data-01.png);
                    width: 184px;
                    height: 95px;
                }
                .coverage-data {
                    background-image: url(../../../../assets/image/student/data-02.png);
                }
            }
        }
    }
    .live-room-main {
        .main-tag-add-box {
            display: flex;
        }
        .room-main-tag {
            .main-tag-check-box {
                display: flex;
                flex-direction: column;
                position: relative;
                .main-tag-check-del {
                    position: absolute;
                    top: 0;
                    left: 29%;
                    cursor: pointer;
                }
                 ::v-deep .el-checkbox {
                    margin: 0 11px 16px 0;
                }
                 ::v-deep .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                }
                .main-tag-check-item {
                    width: 361px;
                    height: auto;
                    border: 1px solid #0c0e3f;
                    border-radius: 4px;
                    padding: 21px 0 0 11px;
                    background: rgb(12, 14, 63);
                }
            }
            .main-tag-agreement {
                .el-checkbox {
                    margin: 0px 0 0 0;
                }
            }
        }
    }
    .submit-btn {
        & {
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}